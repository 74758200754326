import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import BlurbSlider from "../components/blurbslider";
import { PageText, PageH1 } from "../styledcomponents/layoutstyles";
import {
  FrontP,
  CoverImageStarDiv,
} from "./../styledcomponents/frontpagestyles";
import "../css/flickity.css";

// const CoverImageFan = () => (
//   <Link to="/about">
//     <CoverImageDiv>
//       <div>
//         <div>
//           <div />
//         </div>
//       </div>
//     </CoverImageDiv>
//   </Link>
// );

const CoverImage = () => {
  const [moving, setMoving] = React.useState(false);
  const go = () => setMoving(true);
  const stop = () => setMoving(false);
  return (
    <Link to="/about" onMouseEnter={go} onMouseLeave={stop}>
      <CoverImageStarDiv className={moving ? "on" : null}>
        <div>
          <div>
            <div>
              <div>
                <div>
                  <div />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div />
      </CoverImageStarDiv>
    </Link>
  );
};

const IndexPage = () => (
  <Layout>
    <PageH1>
      Eric Giroux’s <em>Ring On Deli</em>
    </PageH1>
    <PageText>
      <FrontP>
        When the Bounty Bag supermarkets erupt with worker protests, deli-clerk
        Ray is forced to take a side.
      </FrontP>
      <CoverImage />
    </PageText>
    <BlurbSlider />
  </Layout>
);

export default IndexPage;
